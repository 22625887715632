import { atom } from 'jotai';
import InkyAPI from '../API/InkyAPI';
import { ApiError, ApiErrorStatus, InkyApiV2 } from '../API/InkyApiV2';
import { AccountInfo, SignupResponseStatus } from '../Models/AccountInfo';
import { InkyDashApiV2 } from '../API/InkyDashApiV2';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from './OverlayStore';
import CreateUserPayload from '../Models/CreateUserPayload';
import { atomWithStorage } from 'jotai/utils';
import { AxiosError } from 'axios';
import { MyBooksAtom } from './MyBooksStore';
import { FetchCartAtom } from './CartStore';
import { Platform, PlatformAtom } from './GenericAtoms';
import { ReadingMode } from '../InkyPen/Reader/Reader';
import { InstrumentationSessionList } from '../Models/Instrumentation/InstrumentationPayload';
import inkyAPI from '../API/InkyAPI';

const InkyApi = InkyApiV2.shared();
const InkyDashApi = InkyDashApiV2.shared();

// Login atom
export type LoginStatus =
    "Unknown"|
    "LoggedOut"|
    "LoggingIn"|
    "LoggedIn";


export const LoginStatusAtom = atom<LoginStatus>("Unknown");
export const AccountPollingIsRunning = atom<boolean>(false);
export const AccountSubscriptionIsRunning = atom<boolean>(false);

export const AccountInfoAtom = atom(new AccountInfo());

export const ForgotPasswordTimerAtom = atom(0);

export const MatureContentWarningAccepted = atomWithStorage('MatureContentWarningAccepted', false);


//region Accesstoken
function setAccessToken(accessToken): void {
    InkyApi.AccessToken = accessToken;
    InkyDashApi.AccessToken = accessToken;
}

const PersistentAccessTokenAtom = atom('');
export const AccessTokenAtom = atom((get) => {
        const storedValue = get(PersistentAccessTokenAtom);
        if (storedValue) {
            return get(PersistentAccessTokenAtom);
        }
    },
    async (get, set, newAccessToken: string) => {
        let currentNewAccessToken = newAccessToken;

        // If we unset the access-token we intend to log out
        if (currentNewAccessToken == null) {
            set(PersistentAccessTokenAtom, null); // Set the reference to null
            localStorage.removeItem('AccessToken'); // Remove the persistent storage
            setAccessToken(null); // Also tell the legacy api that we dont have a accesstoken anymore
            set(LoginStatusAtom, "LoggedOut"); // We can set the loginstatus to logged out
            return;
        }

        // Update the AccessToken in the InkyAPi Singleton
        setAccessToken(currentNewAccessToken);

        // If new AccessToken is set, lets verify that it is valid.
        if (currentNewAccessToken !== null) {

            // Validate by attempting to ping the Account-info endpoint.
            try {
                const accountInfo = await InkyApi.FetchAccountInfo();
                set(AccountInfoAtom, accountInfo);
            } catch (e) {
                console.warn('[AccountStore] Could not validate AccountInfo', e);
                if (e instanceof ApiError) {

                    if (e.status == ApiErrorStatus.InvalidAccessToken) {

                        // Tro to fetch a new AccessToken
                        // Request new access-token based on refresh-token.
                        try {
                            // const newAccessToken = await InkyApi.LoginWithRefreshToken();
                            const refreshToken = localStorage.getItem('RefreshToken');
                            const newAccessToken = await InkyApi.LoginWithRefreshTokenFromLocalStorage(refreshToken);
                            currentNewAccessToken = newAccessToken.access_token;
                            setAccessToken(currentNewAccessToken);
                            try {
                                const accountInfo = await InkyApi.FetchAccountInfo();
                                set(AccountInfoAtom, accountInfo);
                                await set(AccessTokenAtom, InkyApi.AccessToken);
                            } catch (e) {
                                console.error('Failed setting account-info twice.', e);
                                console.error(e);
                            }


                        } catch (e) {

                            console.error('Api Error', e);
                        }
                    }
                } else {
                    if (e.status === 404) {
                        console.error('RefreshToken not found', e);
                        set(LoginStatusAtom, 'LoggedOut');
                        localStorage.removeItem('AccessToken');
                        localStorage.removeItem('RefreshToken');
                    } else {
                        console.error('Something went wrong while requesting accountinfo', e);
                        throw (e);
                    }
                }

            }

            if (currentNewAccessToken === undefined) {
                // if we somehow can't fetch the access token with refresh token
                set(LoginStatusAtom, "LoggedOut");
                localStorage.removeItem('AccessToken');
                set(PersistentAccessTokenAtom, newAccessToken);
            } else {
                if (LoginTypeStore.getLoginType() === LoginType.Checkout) {
                    set(LoginOverlayVisible, false); // Disables the loginOverlauy
                    set(CheckoutOverlayVisible, true);
                }
                localStorage.setItem('AccessToken', currentNewAccessToken);
                set(LoginStatusAtom, "LoggedIn");
                set(PersistentAccessTokenAtom, currentNewAccessToken);
            }
        } else {

            try {
                // if access token deleted manually
                const refreshToken = localStorage.getItem('RefreshToken');
                if (refreshToken == null) {
                    console.debug('[Account] Client does not possess neither accessToken or refreshToken, cancelling login.');
                    set(LoginStatusAtom, "LoggedOut");
                    return;
                }
                const newAccessToken = await InkyApi.LoginWithRefreshTokenFromLocalStorage(refreshToken);
                currentNewAccessToken = newAccessToken.access_token;
                setAccessToken(currentNewAccessToken);
                try {
                    const accountInfo = await InkyApi.FetchAccountInfo();
                    set(AccountInfoAtom, accountInfo);
                    await set(AccessTokenAtom, InkyApi.AccessToken);
                } catch (e) {
                    console.error('Failed setting account-info twice.', e);
                    console.error(e);
                }

                if (currentNewAccessToken === undefined) {
                    // if we somehow can't fetch the access token with refresh token
                    set(LoginStatusAtom, "LoggedOut");
                    localStorage.removeItem('AccessToken');
                } else {
                    if (LoginTypeStore.getLoginType() === LoginType.Checkout) {
                        set(LoginOverlayVisible, false); // Disables the loginOverlauy
                        set(CheckoutOverlayVisible, true);
                    }
                    localStorage.setItem('AccessToken', currentNewAccessToken);
                    set(LoginStatusAtom, "LoggedIn");
                    set(PersistentAccessTokenAtom, currentNewAccessToken);
                }

            } catch (e) {

                console.error('Api Error', e);
                set(LoginStatusAtom, "LoggedOut");
                localStorage.removeItem('AccessToken');
                set(PersistentAccessTokenAtom, newAccessToken);
            }


        }
        // LoginTypeStore.setLoginType(LoginType.Normal);
        await set(FetchCartAtom);
    });

AccessTokenAtom.onMount = (set): void => {
    console.log('Loading accesstoken');
    set(localStorage.getItem('AccessToken'));
};
//endregion

//region RefreshToken
function setRefreshToken(refreshToken): void {
    InkyApi.RefreshToken = refreshToken;
    InkyDashApi.RefreshToken = refreshToken;
}

const PersistentRefreshToken = atom('');
export const RefreshTokenAtom = atom(
    (get) => {
        const storedValue = get(PersistentRefreshToken);
        if (storedValue) {
            return get(PersistentRefreshToken);
        }
    },
    (get, set, refreshToken: string) => {
        set(PersistentRefreshToken, refreshToken);
        setRefreshToken(refreshToken);
        if (refreshToken != null) {
            localStorage.setItem('RefreshToken', refreshToken);
            set(LoginStatusAtom, "LoggedIn");
        } else {
            set(LoginStatusAtom, "LoggedOut");
            localStorage.removeItem('RefreshToken');
        }
    },
);
RefreshTokenAtom.onMount = (set): void => {
    set(localStorage.getItem('RefreshToken'));
};
//endregion

//region Login
export interface Credentials {
    email: string;
    password: string;
}

export const LoginErrorMessageAtom = atom<string | false>(false);
export const LoginAtom = atom(
    get => get(LoginStatusAtom),
    async (get, set, payload: Credentials) => {
        set(LoginStatusAtom, "LoggingIn");
        set(LoginErrorMessageAtom, false);


        if (!payload.email || !payload.password) {
            set(LoginStatusAtom, "LoggedOut");
            return;
        }

        try {
            const loginResponse = await InkyAPI.login(payload.email, payload.password);

            if (loginResponse.refresh_token != null) {
                set(AccessTokenAtom, loginResponse.access_token);
                set(RefreshTokenAtom, loginResponse.refresh_token);
                setAccessToken(loginResponse.access_token);
                set(LoginStatusAtom, "LoggedIn");

            }
        } catch (e) {

            if (e instanceof AxiosError) {

                if (e.response.status === 403) {
                    set(LoginErrorMessageAtom, 'Wrong password.');
                }


                console.log('Network error!', e);
            } else {
                console.error('Unknown error', e);
            }


            set(LoginStatusAtom, "LoggedOut");
        }

        await set(FetchCartAtom);
    },
);
//endregion

//region Logout
export const LogoutAtom = atom(
    get => {
        get(LoginStatusAtom);
    },
    (get, set) => {
        // localStorage.removeItem('AccessToken');
        let accountInfo = get(AccountInfoAtom);
        if (accountInfo.roles) {
            accountInfo = { ...accountInfo, roles: accountInfo.roles.filter(role => role !== 'admin') };
            set(AccountInfoAtom, accountInfo);
        }
        set(RefreshTokenAtom, null);
        set(AccessTokenAtom, null);
        localStorage.removeItem('forgot_password');
        set(AccountInfoAtom, new AccountInfo());
        set(MyBooksAtom, []);
    },
);
//endregion

//region SignUp
export const SignUpStatus = atom<SignupResponseStatus | false>(false);
export const SignUpActionAtom = atom(
    (get) => get(SignUpStatus),
    async (get, set, update: Credentials) => {
        console.log('[SignUp] Attempting to register new account.');

        const signupResponse = await InkyApiV2.shared().Signup(new CreateUserPayload(update.email, update.password));

        switch (signupResponse) {
            case SignupResponseStatus.Successful:
                // Log user in
                set(LoginAtom, { email: update.email, password: update.password });
                set(SignUpStatus, signupResponse);
                break;
            case SignupResponseStatus.TenantError:
                // Update the status. Signup will re-render and show error message
                set(SignUpStatus, signupResponse);
                break;
            case SignupResponseStatus.InvalidEmail:
                // Update the status. Signup wil re-render and show error message
                set(SignUpStatus, signupResponse);
                break;
            case SignupResponseStatus.UnknownError:
                // Update the status. Signup wil re-render and show error message
                set(SignUpStatus, signupResponse);
                break;

        }

    },
);

//endregion

//region Admin-stores
export const AdminBuyPass = atomWithStorage('AdminBuyPass', false);
//endregion


export const CanUpdatePaymentDetails = atom(async get => {
    const platform = get(PlatformAtom);
    //get account info so this refreshes when the user logs in
    get(AccountInfoAtom);

    const platformString = platform === Platform.Web ? 'web' : 'steam';

    return await InkyApi.GetCanUpdatePaymentDetails(platformString).then((canUpdatePaymentDetails) => {
        return canUpdatePaymentDetails;
    }).catch(
        (error) => {
            console.error('Failed to get canUpdatePaymentDetails', error);
            return false;
        },
    );
});

//region Instrumentation

// Main instrumentation atom. Used for real time tracking of user reading behavior.
export const InstrumentationAtom = atom<InstrumentationSessionList>({ sessions: [] });
// Whenever we prepare a push to the backend, we move the instrumentation data to this atom.
// Only push to this if its empty. If it's not empty, It's ready to be pushed to the backend.
// The logic for preparing and pushing to backend is done in index.tsx to be able to push independently from the reader-view.
export const InstrumentationPendingPushAtom = atom<InstrumentationSessionList>({sessions: [] });
// Setter-atom for adding pageSessions to the instrumentation.
export const updateSessionData = atom(
    null,
    (get, set, sessionData: {productId: number, pageNumber: number}) => {
        //console.debug("[Instrumentation] Updating session data", sessionData);

        const currentInstrumentation = get(InstrumentationAtom);
        const platform = get(PlatformAtom);
        //console.log("[Instrumentation] Current Instrumentation", currentInstrumentation);

        // Create a ref to the current session. if there is none, create one.
        let currentSession = currentInstrumentation.sessions.find(session => session.comicId === sessionData.productId);
        if(!currentInstrumentation.sessions.find(session => session.comicId === sessionData.productId)){
            currentInstrumentation.sessions.push({
                comicId: sessionData.productId,
                mode: ReadingMode.Page,
                platform: platform,
                pageSessions: []
            });
            currentSession = currentInstrumentation.sessions.find(session => session.comicId === sessionData.productId);
        }

        // Create a ref to any active pagesessions that has not concluded.
        const activePageSessions = currentSession.pageSessions.filter(pageSession => pageSession.end === undefined);
        if(activePageSessions.length > 0){
            const activePageSession = activePageSessions[0];
            activePageSession.end = new Date().toISOString();
            console.debug(`[Instrumentation] Concluded page ${activePageSession.pageNumber}. Duration: ${new Date(activePageSession.end).getTime() - new Date(activePageSession.start).getTime()}ms`);
        }

        // Create a new pagesession and add it to the current session.
        currentSession.pageSessions.push({
            pageNumber: sessionData.pageNumber,
            start: new Date().toISOString(),
            end: undefined
        });

        // Update the instrumentation atom
        set(InstrumentationAtom, currentInstrumentation);
        //console.log("[Instrumentation] Updated Instrumentation", currentInstrumentation);



    },
);
// Setter atom for preparing a push to the backend.
export const prepareInstrumentationPushAtom = atom(
    null,
    (get, set) => {
        const currentInstrumentation = get(InstrumentationAtom);
        const pendingInstrumentation = get(InstrumentationPendingPushAtom);

        if(pendingInstrumentation.sessions.length === 0 && currentInstrumentation.sessions.length > 0) {
            console.debug('[Instrumentation] Preparing instrumentation for push to backend.');
            set(InstrumentationPendingPushAtom, currentInstrumentation);
            set(InstrumentationAtom, { sessions: [] });
        }
    }
);
// Setter atom for the actual push to the backend.
export const sendPreparedInstrumentationToBackendAtom = atom(
    null,
    async (get, set) => {
        const pendingInstrumentation = get(InstrumentationPendingPushAtom);

        if(pendingInstrumentation.sessions.length === 0) {
            return;
        }


        console.debug('[Instrumentation] Sending instrumentation to backend.');
        const response = await inkyAPI.sendInstrumentationData(pendingInstrumentation, null);
        if(response.status != 200){ // 200 - OK
            console.warn("[Instrumentation] Sending instrumentation failed. Retrying next time.");
            return;
        }

        console.debug('[Instrumentation] Instrumentation success sent. Cleaning up.', response.data);
        set(InstrumentationPendingPushAtom, { sessions: [] });


    }
);


//endregion

