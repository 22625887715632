import React, { Fragment, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from '../../Devon/Components/InkyLink';
import './Reader.scss';
import Hammer from 'react-hammerjs';
import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'hammerjs';
import apiClient from '../../API/InkyAPI';
import { Page } from '../../Models/Page';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactResizeDetector from 'react-resize-detector';
import Axios, { CancelTokenSource } from 'axios';
import { InstrumentationSessionList } from '../../Models/Instrumentation/InstrumentationPayload';
import { Col, Row } from 'react-bootstrap';
import { Series } from '../../Models/Series';
import { DiscoverItem } from '../../Models/DiscoverItem';
import { InkyPenButton } from '../InkyPenButton/InkyPenButton';
import CachedImage from '../../Components/CachedImage';
import ReaderPage from '../../Components/Reader/ReaderPage';
import { Product } from '../../Models/Product';

const MAX_RELATIVE_ZOOM = 3;

export enum ReadingMode {
    Page, // Vertical Reader with option for horizontal view.
    Scroll, // Scroll only. Have unique navigation
    Strip // Single page comics. Centered page. No UI, expect next/previous product in series.
}


export interface ReaderProps extends RouteComponentProps {
    isFullScreenEnabled: boolean;
    preventKeyboardInput: boolean;
    onFullScreenChange: () => void;
    product?: Product;
    productsInSeries?: Product[];
    reverseReadingMode: boolean;
    readingMode: ReadingMode; // TODO: WIP: Not yet fully implemented
    initialPage: number; //The current page number.
    onClose?: () => void;
    onNextChapter: () => void;
    onPreviousChapter: () => void;
    onOpenChapter: (id: number) => void;
    onSetCurrentPage: (currPage: number) => void;
    onEndCardVisible: (isVisible: boolean) => void;
    sendInstrumentationData: (data: InstrumentationSessionList) => void;
    series: Series;
    endCard: (goBackCallBack) => JSX.Element;
    doublePage: boolean; //Whether we are currently showing a double page.
}

interface Point {
    x: number;
    y: number;
    pageHit: boolean;
}

interface Zoom {
    fullPage: number;
    relative: number;
}

interface ReaderState {
    height: number;
    width: number;
    currentPage: number; //The current page number.
    pages: Page[];
    transitionTime: string;

    //These numbers control the zooming and positioning of the page.
    zoom: Zoom;

    pageOffsetX: number; //This is the offset in screen pixels to put the current page at 0 on the x-axis.

    panOffsetX: number; //The number of pixels the image is offset due to panning and/or centering on the x-axis.
    panOffsetY: number; //The number of pixels the image is offset due to panning and/or centering on the y-axis.

    panDeltaX: number; //The number of pixels to pan, while still panning, on the x-axis.
    panDeltaY: number; //The number of pixels to pan, while still panning, on the y-axis.

    pinchEventStartScale: number;
    zoomOnStartPinch: number;
    isPinching: boolean;
    pinchX: number;
    pinchY: number;
    sideBarWidth: number;

    topAndBottomBarVisible: boolean;
    showChapters: boolean;
    leftSidebarVisible: boolean;
    rightSidebarVisible: boolean;

    isFullScreen: boolean;
    mouseButtonDown: boolean; //Set to true when the mouse button is pressed down and false when released.
    mouseButtonStartX: number;
    mouseButtonStartY: number;
    showModal: boolean;
    modalTitle: string;
    modalBody: string;

    chapterDropDownIsOpen: boolean;
    pageDropDownIsOpen: boolean;

    showEndCard: boolean;
    endCardFetched: boolean;
    endCardGenreComics: DiscoverItem[];
    endCardIPComics: DiscoverItem[];
    endCardNewPopComics: DiscoverItem[];
}

class Reader extends React.Component<ReaderProps, ReaderState> {
    controlsTimerHandle: number;
    reader: HTMLDivElement;
    private tokenSource: CancelTokenSource;

    constructor(props: ReaderProps) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.toggleTopAndBottomBar = this.toggleTopAndBottomBar.bind(this);
        this.toggleLeftSidebar = this.toggleLeftSidebar.bind(this);
        this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
        this.zoomToPage = this.zoomToPage.bind(this);
        this.zoomTo = this.zoomTo.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onTap = this.onTap.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.moveForward = this.moveForward.bind(this);
        this.moveBack = this.moveBack.bind(this);
        this.onPan = this.onPan.bind(this);
        this.pan = this.pan.bind(this);
        this.onPanEnd = this.onPanEnd.bind(this);
        this.onPinchIn = this.onPinchIn.bind(this);
        this.onPinchOut = this.onPinchOut.bind(this);
        this.onPinchStart = this.onPinchStart.bind(this);
        this.onPinchEnd = this.onPinchEnd.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.pinchScale = this.pinchScale.bind(this);
        this.worldToImageCoordinate = this.worldToImageCoordinate.bind(this);
        this.imageToWorldCoordinate = this.imageToWorldCoordinate.bind(this);
        this.onPreviousPageClick = this.onPreviousPageClick.bind(this);
        this.onNextPageClick = this.onNextPageClick.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.state = {
            height: 0,
            width: 0,
            currentPage: this.props.initialPage,
            pages: [],
            zoom: { relative: 1.0, fullPage: 1.0 },
            isPinching: false,
            transitionTime: '400ms',
            pageOffsetX: 0,
            panOffsetX: 0,
            panOffsetY: 0,
            panDeltaX: 0,
            panDeltaY: 0,
            pinchEventStartScale: 0,
            pinchX: 0,
            pinchY: 0,
            zoomOnStartPinch: 0,
            showChapters: false,
            topAndBottomBarVisible: false,
            leftSidebarVisible: false,
            rightSidebarVisible: false,
            isFullScreen: false,
            sideBarWidth: 0,
            mouseButtonDown: false,
            mouseButtonStartX: 0,
            mouseButtonStartY: 0,
            showModal: false,
            modalTitle: '',
            modalBody: '',
            chapterDropDownIsOpen: false,
            pageDropDownIsOpen: false,
            showEndCard: false,
            endCardFetched: false,
            endCardGenreComics: null,
            endCardIPComics: null,
            endCardNewPopComics: null,
        };
    }

    componentDidUpdate(prevProps: ReaderProps, prevState: ReaderState): void {
        if (this.props.product.id !== prevProps.product.id) {
            this.setState({ pages: [] }, this.loadPageList);
        }

        if(this.props.doublePage !== prevProps.doublePage){
            this.zoomToPage(this.state.currentPage)
        }

        if(this.state.showEndCard != prevState.showEndCard){
            this.props.onEndCardVisible(this.state.showEndCard);
        }

        if(this.props.readingMode !== prevProps.readingMode){
            console.log("Reading Mode has changed");
            this.removeEventListeners(prevProps.readingMode);
            this.addEventListeners(this.props.readingMode);

            this.zoomToPage(this.state.currentPage);

        }

    }

    async loadPageList(): Promise<void> {
        apiClient
            .getPages(this.props.product.id, this.tokenSource)
            .then(response => {
                response.data.forEach((page, index) => {

                    page.opacity = 0;
                });
                //const currComic = this.props.comics.find(c => c.id === this.props.comicId);
                this.setState(
                    {
                        pages: response.data,
                        currentPage: this.props.initialPage,
                    },
                    () => {
                        this.zoomToPage(this.state.currentPage);
                    },
                );
            })
            .catch(reason => {
                //TODO: Handle 403 here.
                this.setState({
                    showModal: true,
                    modalTitle: 'Unable to open comic',
                    modalBody:
                        'Unfortunately you can not read this comic. Please log in to read non-free comics.',
                });
            });
    }

    handleCloseModal = (): void => {
        this.closeReader();
    };

    async componentDidMount(): Promise<void> {
        this.tokenSource = Axios.CancelToken.source();
        this.setState(
            {
                height: this.reader.offsetHeight,
                width: this.reader.offsetWidth,
            },
            () => {
                this.toggleTopAndBottomBar();
                this.toggleLeftSidebar();
                this.toggleRightSidebar();
                this.controlsTimerHandle = window.setTimeout(() => {
                    this.controlsTimerHandle = 0;
                    this.hideControls();
                }, 3000);
            },
        );
        await this.loadPageList();

        this.addEventListeners(this.props.readingMode);

        //await this.fetchEndCardData();
    }

    addEventListeners = (readingMode: ReadingMode) => {
        switch (readingMode) {
            case ReadingMode.Scroll:
                console.log("Reading Mode is Vertical, Adding scroll event listener.")
                const verticalReaderDiv = document.querySelector('.Reader-Vertical');
                verticalReaderDiv?.addEventListener('scroll', this.onScrollVertical);
                break;

            default:
                window.addEventListener('wheel', this.onWheel);
                window.addEventListener('keydown', this.onKeyPress);
                break;
        }
    }

    removeEventListeners = (readingMode: ReadingMode) => {
        switch (readingMode) {
            case ReadingMode.Scroll:
                console.log("Reading Mode is Vertical, Removing scroll event listener.")
                const verticalReaderDiv = document.querySelector('.Reader-Vertical');
                verticalReaderDiv?.removeEventListener('scroll', this.onScrollVertical);
                break;

            default:
                window.removeEventListener('wheel', this.onWheel);
                window.removeEventListener('keydown', this.onKeyPress);
                break;
        }
    }

    componentWillUnmount(): void {
        this.tokenSource.cancel();

        this.removeEventListeners(this.props.readingMode);

        if (this.controlsTimerHandle !== 0) {
            window.clearTimeout(this.controlsTimerHandle);
        }
    }

    onResize(width: number, height: number): void {
        this.setState({ width: width, height: height }, () => {
            if (this.state.zoom.relative <= 1.0)
                this.zoomToPage(this.state.currentPage);
            else {
                //TODO: Do we need to adjust the page?
            }
        });
    }

    clamp(value: number, min_: number, max_: number): number {
        if (value > max_) {
            return max_;
        }
        if (value < min_) {
            return min_;
        }
        return value;
    }

    turnPageLeft():void{
        if (this.state.zoom.relative <= 1) this.moveBack();
        else {
            this.pan(50, 0, true);
        }
    }

    turnPageRight():void {
        if (this.state.zoom.relative <= 1) this.moveForward();
        else {
            this.pan(-50, 0, true);
        }
    }

    onScrollVertical = (ev: Event): void => {


        // Get an array of all DOM pages
        const pages = document.querySelectorAll('.reader-page');

        // Print whetever the div is visible on the screen
        let currentPageIndex = null;
        pages.forEach((page, index) => {
            if(currentPageIndex){return;}
            const boundingBox = page.getBoundingClientRect();
            const pageIsFullyAboveViewport = boundingBox.bottom > 0 ?? false;
            //console.log(`Page ${index} is visible: `, pageIsFullyAboveViewport);

            // Iterate until the first pageIsFullyAboveViewport = false
            if(pageIsFullyAboveViewport){
                currentPageIndex = index;
                return;
            }

        });

        // If page has changed, update the state
        if(currentPageIndex !== this.state.currentPage){
            this.props.onSetCurrentPage(currentPageIndex);
        }



    }

    onKeyPress(ev: KeyboardEvent): void {
        // Disable this when login is open.
        if(this.props.preventKeyboardInput){
            return;
        }

        switch (ev.code) {
            case 'Escape':
                if (this.state.isFullScreen) {
                    this.setState({ isFullScreen: !this.state.isFullScreen });
                } else
                    this.closeReader();
                break;
            case 'KeyD':
            case 'ArrowRight':
                if(this.props.reverseReadingMode) {
                    this.turnPageLeft()
                } else{
                    this.turnPageRight()
                }
                break;
            case 'KeyA':
            case 'ArrowLeft':
                if(this.props.reverseReadingMode) {
                    this.turnPageRight()
                } else{
                    this.turnPageLeft()
                }
                break;
            case 'KeyW':
            case 'ArrowUp':
                if (this.state.zoom.relative > 1) this.pan(0, 50, true);
                break;
            case 'KeyS':
            case 'ArrowDown':
                if (this.state.zoom.relative > 1) this.pan(0, -50, true);
                break;
            case 'Digit9':
                //this.zoomToPage(9);
                break;
            case 'KeyX':
                //this.zoomToPage(this.state.currentPage);
                break;
/*            case 'NumpadSubtract':
            case 'NumpadAdd':
            case 'ShiftRight':
            case 'ShiftLeft':
                if (this.state.zoom.relative <= 1) {
                    const screenX = this.state.width / 2;
                    const screenY = this.state.height / 2;
                    const point = this.worldToImageCoordinate(screenX, screenY);
                    this.zoomTo(
                        this.state.currentPage,
                        this.props.doublePage,
                        screenX,
                        screenY,
                        point.x,
                        point.y,
                        2,
                        '400ms',
                    );
                } else {
                    this.zoomToPage(this.state.currentPage);
                }*/
        }
    }

    onPreviousPageClick(): void {
        this.moveBack();
    }

    onNextPageClick(): void {
        this.moveForward();
    }

    /// This function converts coordinates on the current page to coordinates on the screen.
    imageToWorldCoordinate(imageX: number, imageY: number): Point {
        const dimensions = this.determineDimensions();
        const pageWidth = dimensions.width;
        const pageHeight = dimensions.height;

        const isHit =
            imageX >= 0 &&
            imageX <= pageWidth &&
            (imageY >= 0 && imageY <= pageHeight);

        const z = this.state.zoom.relative * this.state.zoom.fullPage;

        const screenX = imageX * z + this.state.panOffsetX;
        const screenY = imageY * z + this.state.panOffsetY;

        return {
            x: screenX,
            y: screenY,
            pageHit: isHit,
        };
    }

    /// This converts coordinates on the screen to coordinates on the current page.
    worldToImageCoordinate(screenX: number, screenY: number): Point {
        const dimensions = this.determineDimensions();
        const pageWidth = dimensions.width;
        const pageHeight = dimensions.height;

        const z = this.state.zoom.relative * this.state.zoom.fullPage;

        const x0 = screenX - this.state.panOffsetX; //screen pixels into the image.
        const y0 = screenY - this.state.panOffsetY;

        const imageX = x0 / z; //Converting to image pixels.
        const imageY = y0 / z;

        const isHit =
            imageX >= 0 &&
            imageX <= pageWidth &&
            (imageY >= 0 && imageY <= pageHeight);
        return {
            x: imageX,
            y: imageY,
            pageHit: isHit,
        };
    }

    onWheel(ev: WheelEvent): void {
        if (this.state.pages.length === 0) return;
        if (this.state.chapterDropDownIsOpen) return;
        if (this.state.pageDropDownIsOpen) return;
        if (this.state.topAndBottomBarVisible) return; // We dont want the reader to scroll if the menu is open.

        //console.log(ev);
        const point = this.worldToImageCoordinate(ev.x, ev.y);
        if (!point.pageHit) return; //Only zoom in or out while cursor is on image.

        const factor = ev.deltaY < 0 ? 1.2 : 0.8;
        const newZoom = this.clamp(
            this.state.zoom.relative * factor,
            1,
            MAX_RELATIVE_ZOOM,
        );

        if (this.state.zoom.relative * factor <= 1) {
            this.zoomToPage(this.state.currentPage);
        } else {
            this.zoomTo(
                this.state.currentPage,
                this.props.doublePage,
                ev.x,
                ev.y,
                point.x,
                point.y,
                newZoom,
                '0ms',
            );
        }
    }

    onMouseMove = (ev: React.MouseEvent): void => {
        if (this.state.pages.length === 0) return;
        if (this.state.chapterDropDownIsOpen) return;
        if (this.state.pageDropDownIsOpen) return;
        //Middle mouse button panning
        if (this.state.mouseButtonDown && this.state.zoom.relative > 1) {
            this.pan(
                ev.clientX - this.state.mouseButtonStartX,
                ev.clientY - this.state.mouseButtonStartY,
                false,
            );
        }
    };

    isLastChapter(): boolean {
        const currentComicIndex = this.props.productsInSeries?.findIndex(x => x.id === this.props.product.id);
        return this.props.productsInSeries?.length - 1 <= currentComicIndex;
    }

    allowDirectPagination(): boolean {
        return [ReadingMode.Strip, ReadingMode.Scroll].includes(this.props.readingMode);
    }

    moveForward(): void {

        // Check if we are on the last page
        if (this.state.currentPage + 1 >= this.state.pages.length) {
            // For Strips we want to not show the endcard, but instruct the Reader-controller to move to the next product.
            if(this.allowDirectPagination()){
                this.props.onNextChapter();
                return;
            }

                this.setState({ showEndCard: true });
                return;
        }

        // If endcard is visible hide it (since we allready know that it should not be visible.
        if (this.state.showEndCard) {
            this.setState({ showEndCard: false });
        }

        if (this.state.pages.length === 1) {
            this.props.onNextChapter();
            return;
        }
        console.debug(
            `Move forward from page ${this.state.currentPage} to page ${this
                .state.currentPage + 1}`,
        );

        if(this.props.doublePage && this.state.currentPage !== 0){
            this.zoomToPage(this.state.currentPage + 2);
        }
        else {
            this.zoomToPage(this.state.currentPage + 1);
        }
    }

    moveBack(): void {

        // If endcard is visible. hide it. Dont paginate.
        if (this.state.showEndCard) {
            this.setState({ showEndCard: false });
            return;
        }

        // If the product only have one page, we want to go back to the previous product.
        // Should we change this to check for reading mode instead?
        if (this.allowDirectPagination()) {
            this.props.onPreviousChapter();
            return;
        }
        console.log(
            `Move forward from page ${this.state.currentPage} to page ${this
                .state.currentPage - 1}`,
        );
        if (this.props.doublePage) this.zoomToPage(this.state.currentPage - 2);
        else this.zoomToPage(this.state.currentPage - 1);
    }

    hideControls = (): void => {
        this.setState({
            topAndBottomBarVisible: false,
            leftSidebarVisible: false,
            rightSidebarVisible: false,
        });
    };
    initiateHideControls = (): void => {
        if (this.controlsTimerHandle !== 0) {
            window.clearTimeout(this.controlsTimerHandle);
        }

        this.controlsTimerHandle = window.setTimeout(() => {
            this.controlsTimerHandle = 0;
            this.hideControls();
        }, 3000);
    };

    toggleTopAndBottomBar(state?: boolean): void {
        if (state) {
            if (this.controlsTimerHandle !== 0) {
                window.clearTimeout(this.controlsTimerHandle);
            }
        }
        this.setState({
            topAndBottomBarVisible:
                state === undefined
                    ? !this.state.topAndBottomBarVisible
                    : state.valueOf(),
        });
    }

    toggleLeftSidebar(state?: boolean): void {
        if (state) {
            if (this.controlsTimerHandle !== 0) {
                window.clearTimeout(this.controlsTimerHandle);
            }
        }
        this.setState({
            leftSidebarVisible:
                state === undefined
                    ? !this.state.leftSidebarVisible
                    : state.valueOf(),
        });
    }

    toggleRightSidebar(state?: boolean): void {
        if (state) {
            if (this.controlsTimerHandle !== 0) {
                window.clearTimeout(this.controlsTimerHandle);
            }
        }
        this.setState({
            rightSidebarVisible:
                state === undefined
                    ? !this.state.rightSidebarVisible
                    : state.valueOf(),
        });
    }

    closeReader = () => {
        if (this.props.onClose) this.props.onClose();
        else {
            if (window.history.length > 0) window.history.back();
            else return <Link to='/' />;
        }
    };

    onMouseUp(ev: React.MouseEvent): void {
        if (ev.button === 1 && this.state.zoom.relative > 1) {
            this.setState({
                mouseButtonDown: false,
                mouseButtonStartX: 0,
                mouseButtonStartY: 0,
                panOffsetX: this.state.panOffsetX + this.state.panDeltaX,
                panOffsetY: this.state.panOffsetY + this.state.panDeltaY,
                panDeltaY: 0,
                panDeltaX: 0,
            });
        }
    }

    onMouseDown(ev: React.MouseEvent): void {
        if (this.state.chapterDropDownIsOpen) return;
        if (this.state.pageDropDownIsOpen) return;

        if (ev.button === 1 && this.state.zoom.relative > 1) {
            this.setState({
                mouseButtonDown: true,
                mouseButtonStartX: ev.clientX,
                mouseButtonStartY: ev.clientY,
            });
        }
    }

    onTap(ev: HammerInput): void {
        if (this.state.pages.length === 0) return;
        if (this.state.chapterDropDownIsOpen) return;
        if (this.state.pageDropDownIsOpen) return;
        const point = this.worldToImageCoordinate(
            ev.pointers[0].clientX,
            ev.pointers[0].clientY,
        );

        if (
            ev.pointerType == "mouse" &&
            point.pageHit &&
            ev.center.y < this.state.height - 90 &&
            ev.center.y > 60
        ) {
            this.toggleTopAndBottomBar();
            this.toggleLeftSidebar();
            this.toggleRightSidebar();

            this.initiateHideControls();
        }
    }

    /**
     * Zooms to the specified page, centering it so that imageX, imageY is at screenX, screenY, scaled to scale
     *
     *
     * @param pageNumber - The page number of the page or the leftmost page.
     * @param doublePage - Whether to render double or single page.
     * @param screenX - The x-point on the screen to which we will center the page(s). This is in screen pixels.
     * @param screenY - The x-point on the screen to which we will center the page(s). This is in screen pixels.
     * @param imageX - The x-point of the image that is to be put on the screenX point. This number is in image pixels without scaling.
     * @param imageY - The y-point of the image that is to be put on the screenY point. This number is in image pixels without scaling.
     * @param scale - The relative scale of the page, where 1 is fitted to the page. 2 is double of the viewport size etc.
     * @param transitionTime - Duration of the zoom animation.
     *
     * @param readingMode - The reading mode of the reader.
     * @returns Nothing
     */
    zoomTo(
        pageNumber: number,
        doublePage: boolean,
        screenX: number,
        screenY: number,
        imageX: number,
        imageY: number,
        scale: number,
        transitionTime: string,
        readingMode?: ReadingMode,
    ): void {

        if(readingMode === ReadingMode.Scroll){

            // Find current Scroll-position
            const verticalReaderDiv = document.querySelector('.Reader-Vertical');
            const scrollPosition = verticalReaderDiv.scrollTop;

            const pages = document.querySelectorAll('.reader-page');
            const currentPageBounds = pages[pageNumber].getBoundingClientRect();

            verticalReaderDiv.scrollTo({top: currentPageBounds.top, behavior: 'smooth'});

            return;
        }

        // Note that translate-origin is 0, 0 and the order of the operations are
        // scaling, then translate. This means that the translate values need to
        // be in screen coordinates.

        // Calculating the new scale, fitting the page and possibly rendering two pages
        // if we can fit the full-height pages within the width.
        const page = this.state.pages[pageNumber];
        let z = this.state.height / page.height;

        if (page.width * z > this.state.width) {
            //Page does not fit in full height. Fit to width.
            z = this.state.width / page.width;
            if (z * page.width > 1200) {
                z = 1200 / page.width;
            }
        }

        const fullScale = z * scale;

        //offset to position the current page such that imageX,imageY is at screenX,screenY
        const dy = screenY - imageY * fullScale;
        const dx = screenX - imageX * fullScale;
        //console.log(`Image offset: ${dx}, ${dy}`);

        //offset to get the correct page(s) into the viewport.
        let moveX = 0;
        if (this.props.reverseReadingMode) {
            for (let i = this.state.pages.length - 1; i > pageNumber; i--) {
                //Todo: We might want to store these values.
                moveX -= this.state.pages[i].width * fullScale;
            }
            if (doublePage && pageNumber !== -1) {
                moveX += this.state.pages[pageNumber + 1].width * fullScale;
            }
        } else {
            for (let i = 0; i < pageNumber; i++) {
                //Todo: We might want to store these values.
                moveX -= this.state.pages[i].width * fullScale;

            }
        }
        // console.log("MoveX is: ", moveX);

        // Show and hide pages by changing their opacity.
        const newPages = this.state.pages;
        newPages[this.state.currentPage].opacity = 0;
        if (this.state.currentPage > 0)
            newPages[this.state.currentPage - 1].opacity = 0;
        if (this.state.currentPage < this.state.pages.length - 1)
            newPages[this.state.currentPage + 1].opacity = 0;

        newPages[pageNumber].opacity = 1;
        if (pageNumber - 1 >= 0) newPages[pageNumber - 1].opacity = 0;
        if (doublePage && pageNumber < this.state.pages.length - 1) {
            newPages[pageNumber + 1].opacity = 1;
            if (pageNumber + 2 < this.state.pages.length)
                newPages[pageNumber + 2].opacity = 0;
        }


        this.setState({
                zoom: {
                    fullPage: z,
                    relative: scale,
                },
                panDeltaX: 0,
                panDeltaY: 0,
                pageOffsetX: moveX,
                panOffsetX: dx,
                panOffsetY: dy,
                currentPage: pageNumber,
                pages: newPages,
                transitionTime: transitionTime,
                isPinching: false,
                sideBarWidth: dx,
            },
            () => {
                this.props.onSetCurrentPage(this.state.currentPage);
            },
        );
    }

    pan(deltaX: number, deltaY: number, finalPan: boolean): void {
        const leftTopImage = this.imageToWorldCoordinate(0, 0);
        let pageWidth = this.state.pages[this.state.currentPage].width;
        if (this.props.doublePage)
            pageWidth += this.state.pages[this.state.currentPage + 1].width;
        const rightBottom = this.imageToWorldCoordinate(
            pageWidth,
            this.state.pages[this.state.currentPage].height,
        );
        //console.log(`Image position is from ${leftTopImage.x},${leftTopImage.y} to ${rightBottom.x}, ${rightBottom.y}`);

        pageWidth = rightBottom.x - leftTopImage.x; //pageWidth is now in world coordinates

        let minDx: number;
        let maxDx: number;
        //If the image width fits inside the viewport
        if (pageWidth < this.state.width) {
            // - don't allow to pan the left edge outside of the left side of the viewport
            // - don't allow to pan the right edge outside of the right side of the viewport
            minDx = leftTopImage.x * -1;
            maxDx = this.state.width - rightBottom.x;
        } else {
            // - don't allow to pan the left edge inside the left side of the viewport
            // - don't allow to pan the right edge inside the right side of the viewport
            maxDx = leftTopImage.x * -1;
            minDx = this.state.width - rightBottom.x;
        }

        const minDy = (rightBottom.y - this.state.height) * -1;
        const maxDy = leftTopImage.y * -1;

        //console.log(`Max movement ${minDx},${maxDx} x and ${minDy},${maxDy} y`);

        const clampedDeltaX = this.clamp(deltaX, minDx, maxDx);
        const clampedDeltaY = this.clamp(deltaY, minDy, maxDy);

        if (this.state.zoom.relative > 1) {
            if (!finalPan)
                this.setState({
                    panDeltaX: clampedDeltaX,
                    panDeltaY: clampedDeltaY,
                    transitionTime: '0ms',
                });
            else
                this.setState({
                    panDeltaX: 0,
                    panDeltaY: 0,
                    panOffsetX: this.state.panOffsetX + clampedDeltaX,
                    panOffsetY: this.state.panOffsetY + clampedDeltaY,
                    transitionTime: '0ms',
                });
        } else {
            //We'll fade in the next page and fade out the current page when panning a page
            //that has not been zoomed at all.

            const threshold = this.props.doublePage ? 0.25 : 0.5;
            let fadeIn = Math.abs(deltaX) / (pageWidth * threshold);

            if (fadeIn > 1) fadeIn = 1;

            const fadeOut = 1 - fadeIn;
            if (this.state.currentPage === this.state.pages.length - 1)
                //No fading for last page.
                fadeIn = 0;
            if (this.state.currentPage === 0 && deltaX > 0) fadeIn = 0;

            const newPages = this.state.pages;
            // deltaX > 0 -> dragging right
            // deltaX < 0 -> dragging left
            if ((deltaX > 0 && !this.props.reverseReadingMode) || (deltaX <= 0 && this.props.reverseReadingMode)) {
                if (this.state.currentPage > 0)
                    newPages[this.state.currentPage - 1].opacity = fadeIn;

                if (
                    this.props.doublePage &&
                    this.state.currentPage + 2 < this.state.pages.length
                )
                    newPages[this.state.currentPage + 1].opacity = fadeOut;
                if (
                    !this.props.doublePage &&
                    this.state.currentPage + 1 < this.state.pages.length
                )
                    newPages[this.state.currentPage].opacity = fadeOut;
            } else if ((deltaX > 0 && this.props.reverseReadingMode) || !this.props.reverseReadingMode) {
                newPages[this.state.currentPage].opacity = fadeOut;
                if (
                    this.props.doublePage &&
                    this.state.currentPage + 2 < this.state.pages.length
                )
                    newPages[this.state.currentPage + 2].opacity = fadeIn;
                if (
                    !this.props.doublePage &&
                    this.state.currentPage + 1 < this.state.pages.length
                )
                    newPages[this.state.currentPage + 1].opacity = fadeIn;
            }

            /*console.log(`Current page is ${this.state.currentPage}`);
            console.log(`DEltax: ${deltaX}`)
            console.log(`Next page: ${p}`)*/
            this.setState({
                panDeltaX: deltaX,
                transitionTime: '0ms',
                pages: newPages,
            });
        }
    }

    onPan(ev: HammerInput): void {
        if (this.state.pages.length === 0) return;
        if (this.state.chapterDropDownIsOpen) return;
        if (this.state.pageDropDownIsOpen) return;

        if (this.state.isPinching) return; //Panning is disabled while we are pinching.

        if (ev.center.x === 0 && ev.center.y === 0) {
            console.log(`Ignore pan ${ev.deltaX}x, ${ev.deltaY}`);
            return; //For some reason we get some events with crazy values and center 0,0..
        }
        this.pan(ev.deltaX, ev.deltaY, false);
    }

    onPanEnd(ev: HammerInput): void {
        if (this.state.pages.length === 0) return;

        if (this.state.isPinching) return; //Panning is disabled while we are pinching.

        if (this.state.zoom.relative <= 1) {
            //If the user has panned a full-page more than 50% over, flip the page.
            const leftTopImage = this.imageToWorldCoordinate(0, 0);
            let pageWidth = this.state.pages[this.state.currentPage].width;
            if (this.props.doublePage)
                pageWidth += this.state.pages[this.state.currentPage + 1].width;
            const rightBottom = this.imageToWorldCoordinate(
                pageWidth,
                this.state.pages[this.state.currentPage].height,
            );
            pageWidth = rightBottom.x - leftTopImage.x; //pageWidth is now in world coordinates

            const threshold = this.props.doublePage ? 0.15 / 2 : 0.30 / 2;
            const doFlip = Math.abs(ev.deltaX) / pageWidth > threshold;

            // deltaX > 0 -> dragging right
            // deltaX < 0 -> dragging left
            if (doFlip && ev.deltaX < 0) {
                this.props.reverseReadingMode
                    ? this.moveBack()
                    : this.moveForward();
                return;
            } else if (doFlip) {
                this.props.reverseReadingMode
                    ? this.moveForward()
                    : this.moveBack();
                return;
            }

            const newPages = this.state.pages;
            if (
                this.props.doublePage &&
                this.state.currentPage + 2 < this.state.pages.length - 1
            )
                newPages[this.state.currentPage + 2].opacity = 0;
            if (
                !this.props.doublePage &&
                this.state.currentPage + 1 < this.state.pages.length - 1
            )
                newPages[this.state.currentPage + 1].opacity = 0;
            if (this.state.currentPage - 1 >= 0)
                newPages[this.state.currentPage - 1].opacity = 0;
            newPages[this.state.currentPage].opacity = 1;
            if (
                this.props.doublePage &&
                this.state.currentPage + 1 < this.state.pages.length
            )
                newPages[this.state.currentPage + 1].opacity = 1;

            this.setState({
                panOffsetX: this.state.panOffsetX,
                panOffsetY: this.state.panOffsetY,
                panDeltaX: 0,
                panDeltaY: 0,
                transitionTime: '400ms',
                pages: newPages,
            });
        } else {
            this.setState({
                panOffsetX: this.state.panOffsetX + this.state.panDeltaX,
                panOffsetY: this.state.panOffsetY + this.state.panDeltaY,
                panDeltaX: 0,
                panDeltaY: 0,
                transitionTime: '0ms',
            });
        }
    }

    onPinchStart(ev: HammerInput): void {
        //console.log(`PINCH START SCALE: ${ev.scale}`);
        if (this.state.pages.length === 0) return;

        this.setState({
            pinchEventStartScale: ev.scale,
            zoomOnStartPinch: this.state.zoom.relative,
            isPinching: true,
            transitionTime: '0ms',
            pinchX: ev.center.x,
            pinchY: ev.center.y,
        });
    }

    onPinchEnd(ev: HammerInput): void {
        if (this.state.pages.length === 0) return;

        const deltaPinch = ev.scale;
        if (this.state.zoomOnStartPinch * deltaPinch <= 1) {
            this.zoomToPage(this.state.currentPage);
        } else {
            this.setState({
                zoom: {
                    fullPage: this.state.zoom.fullPage,
                    relative: this.clamp(
                        this.state.zoomOnStartPinch * deltaPinch,
                        1,
                        MAX_RELATIVE_ZOOM,
                    ),
                },
                isPinching: false,
            });
        }
    }

    pinchScale(ev: HammerInput): void {
        const screenX = this.state.pinchX;
        const screenY = this.state.pinchY;
        const point = this.worldToImageCoordinate(screenX, screenY);

        if (!point.pageHit) return;

        const z = this.clamp(
            this.state.zoomOnStartPinch * ev.scale,
            1,
            MAX_RELATIVE_ZOOM,
        );

        this.zoomTo(
            this.state.currentPage,
            this.props.doublePage,
            screenX,
            screenY,
            point.x,
            point.y,
            z,
            '100ms',
        );
    }

    onPinchIn(ev: HammerInput): void {
        if (this.state.pages.length === 0) return;

        this.pinchScale(ev);
    }

    onPinchOut(ev: HammerInput): void {
        if (this.state.pages.length === 0) return;

        this.pinchScale(ev);
    }

    // This function zooms so that page number pageNumber is in the center
    zoomToPage(pageNumber: number): void {
        if (this.state.pages.length === 0) return;
        if(this.props.readingMode === ReadingMode.Scroll ){
            this.zoomToPageVertically(pageNumber);
            return;
        }
        //Make sure next 6 and previous 4 pages are loaded
        let startPage = pageNumber - 4;
        let endPage = pageNumber + 6;
        if (startPage < 0) startPage = 0;
        if (endPage >= this.state.pages.length)
            endPage = this.state.pages.length - 1;
        this.state.pages.forEach((page, index) => {
            page.isLoaded = index >= startPage && index <= endPage;
        });

        const transitionTime = '400ms';
        if (pageNumber < 0) pageNumber = 0;
        if (pageNumber >= this.state.pages.length)
            pageNumber = this.state.pages.length - 1;

        const page = this.state.pages[pageNumber];
        //If page is 0, zoom to single page.
        if (pageNumber === 0) {
            this.zoomTo(
                pageNumber,
                false,
                this.state.width / 2,
                this.state.height / 2,
                page.width / 2,
                page.height / 2,
                1,
                transitionTime,
            );
            return;
        }

        //If this is the last page, show it as a single page.
        if (pageNumber === this.state.pages.length - 1) {
            this.zoomTo(
                pageNumber,
                false,
                this.state.width / 2,
                this.state.height / 2,
                page.width / 2,
                page.height / 2,
                1,
                transitionTime,
            );
            return;
        }

        //If we can only fit 1 page, zoom to single page.
        const screenAspect = this.state.width / this.state.height;

        if (screenAspect >= 1 && this.props.doublePage == true) {
            //Screen is wider than it is tall
            //If next page is a double-spread, show this page only.
            let nextPageWidth = 0;
            let nextPageHeight = 1;
            if (pageNumber + 1 < this.state.pages.length) {
                const nextPage = this.state.pages[pageNumber + 1];
                nextPageWidth = nextPage.width;
                nextPageHeight = nextPage.height;
            }

            const pageAspect = (page.width + nextPageWidth) / page.height;
            const nextPageAspect = nextPageWidth / nextPageHeight;
            if (screenAspect > pageAspect) {
                if (nextPageAspect < screenAspect)
                    this.zoomTo(
                        pageNumber,
                        true,
                        this.state.width / 2,
                        this.state.height / 2,
                        (page.width * 2) / 2,
                        page.height / 2,
                        1,
                        transitionTime,
                    );
                //We can fit two pages.
                else
                    this.zoomTo(
                        pageNumber,
                        false,
                        this.state.width / 2,
                        this.state.height / 2,
                        page.width / 2,
                        page.height / 2,
                        1,
                        transitionTime,
                    ); //Next page is wide. Show only 1.
            } else
                this.zoomTo(
                    pageNumber,
                    false,
                    this.state.width / 2,
                    this.state.height / 2,
                    page.width / 2,
                    page.height / 2,
                    1,
                    transitionTime,
                );
            return;
        } else {
            this.zoomTo(
                pageNumber,
                false,
                this.state.width / 2,
                this.state.height / 2,
                page.width / 2,
                page.height / 2,
                1,
                transitionTime,
            );
            return;
        }
    }

    zoomToPageVertically(pageNumber: number): void {
        console.log("Zooming vertically", pageNumber);

        // Load all pages TODO: Look into doing this more optimized?
        this.state.pages.forEach((page, index) => {
            page.isLoaded = true;
        });


        // Set the opacity of the pages
        this.zoomTo(
            pageNumber,
            false,
            this.state.width / 2,
            this.state.height / 2,
            1,
            1,
            1,
            "100",
            ReadingMode.Scroll
        );


    }

    goToDiscover(): void {
        this.props.history.push('/');
    }

    goToBrowse(): void {
        this.props.history.push('/browse');
    }

    goToMyBooks(): void {
        this.props.history.push('/mycomics');
    }

    showEndCard() {
        if (!this.state.showEndCard && this.props.readingMode != ReadingMode.Scroll) return (<Fragment />);




        // If readingmode is scroll or strip, wrap endcard in a div
        if (this.props.readingMode === ReadingMode.Scroll || this.props.readingMode === ReadingMode.Strip) {
            return(<div className={"vertical-endCard"}>
                {this.props.endCard(() => {
                    this.moveBack();
                })}
            </div>);
        }

        return(this.props.endCard(() => {
            this.moveBack();
        }) );

    }

    renderEndCardComics(): JSX.Element {
        /* We want the backend to serve up these in the future so I'm just hardcoding these for now */
        if (this.state.endCardIPComics != null && this.state.endCardIPComics.length >= 3) {
            return (
                <div className={'suggestedComicContainer'}>
                    <h3>You might also like</h3>
                    <Row className={'row-cols-sm-*'}>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardIPComics[0].type + '/' + this.state.endCardIPComics[0].content.id}>
                                <CachedImage className={'suggestedComicThumb'}
                                             src={this.state.endCardIPComics[0].content.thumbnails[0].url}
                                             alt={this.state.endCardIPComics[0].content.title} /></Link>
                        </Col>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardIPComics[1].type + '/' + this.state.endCardIPComics[1].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardIPComics[1].content.thumbnails[0].url}
                                alt={this.state.endCardIPComics[1].content.title} /></Link>
                        </Col>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardIPComics[2].type + '/' + this.state.endCardIPComics[2].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardIPComics[2].content.thumbnails[0].url}
                                alt={this.state.endCardIPComics[2].content.title} /></Link>
                        </Col>
                    </Row>
                </div>
            );
        } else if (this.state.endCardGenreComics != null && this.state.endCardGenreComics.length >= 3) {
            return (
                <div className={'suggestedComicContainer'}>
                    <h3>You might also like</h3>
                    <Row className={'row-cols-sm-*'}>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardGenreComics[0].type + '/' + this.state.endCardGenreComics[0].content.id}>
                                <CachedImage className={'suggestedComicThumb'}
                                             src={this.state.endCardGenreComics[0].content.thumbnails[0].url}
                                             alt={this.state.endCardGenreComics[0].content.title} /></Link>
                        </Col>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardGenreComics[1].type + '/' + this.state.endCardGenreComics[1].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardGenreComics[1].content.thumbnails[0].url}
                                alt={this.state.endCardGenreComics[1].content.title} /></Link>
                        </Col>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardGenreComics[2].type + '/' + this.state.endCardGenreComics[2].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardGenreComics[2].content.thumbnails[0].url}
                                alt={this.state.endCardGenreComics[2].content.title} /></Link>
                        </Col>
                    </Row>
                </div>
            );
        } else if (this.state.endCardNewPopComics != null && this.state.endCardNewPopComics.length >= 3) {
            return (
                <div className={'suggestedComicContainer'}>
                    <h3>You might also like</h3>
                    <Row className={'row-cols-sm-*'}>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardNewPopComics[0].type + '/' + this.state.endCardNewPopComics[0].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardNewPopComics[0].content.thumbnails[0].url}
                                alt={this.state.endCardNewPopComics[0].content.title} /></Link>
                        </Col>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardNewPopComics[1].type + '/' + this.state.endCardNewPopComics[1].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardNewPopComics[1].content.thumbnails[0].url}
                                alt={this.state.endCardNewPopComics[1].content.title} /></Link>
                        </Col>
                        <Col className={'col-sm-*'}>
                            <Link
                                to={'/' + this.state.endCardNewPopComics[2].type + '/' + this.state.endCardNewPopComics[2].content.id}><CachedImage
                                className={'suggestedComicThumb'}
                                src={this.state.endCardNewPopComics[2].content.thumbnails[0].url}
                                alt={this.state.endCardNewPopComics[2].content.title} /></Link>
                        </Col>
                    </Row>
                </div>
            );
        }
    }

    render(): ReactElement {

        if(this.props.readingMode == ReadingMode.Scroll){
            return(
                <div className={'reader-container'} ref={e => (this.reader = e)}>
                    <div className={'Reader-Vertical'}>


                        {this.state.pages.map(function(item, index) {

                            // Calculate relative height based of a with of that should be 80% of the screen width
                            const pageWidth = window.innerWidth < 425 ? window.innerWidth : Math.min(0.8 * window.innerWidth, 1080);
                            const pageHeight = (item.height / item.width) * pageWidth;


                            return (
                                <ReaderPage
                                    key={item.pageNumber}
                                    isLoaded={item.isLoaded}
                                    url={item.url}
                                    height={pageHeight}
                                    width={pageWidth}
                                    comicId={item.comicID}
                                    pageNumber={item.pageNumber}
                                    opacity={1}
                                />

                            );
                        })}

                        {/* Add endcard here  */}
                        {this.showEndCard()}

                    </div>
                </div>
            );
        }

        return (
                <div className='reader-container' ref={e => (this.reader = e)}>
                    <ReactResizeDetector
                        handleWidth
                        handleHeight
                        onResize={this.onResize}
                    />
                    <Hammer
                        onTap={this.onTap}
                        onPan={this.onPan}
                        onPanEnd={this.onPanEnd}
                        options={{
                            recognizers: {
                                pinch: {
                                    enable: true,
                                },
                                pan: {
                                    enable: true,
                                },
                            },
                        }}
                    >
                        <div
                            className='Reader'
                            style={{
                                width: '100vw',
                                height: '100vh',
                            }}
                            onMouseMove={this.onMouseMove}
                            onMouseDown={this.onMouseDown}
                            onMouseUp={this.onMouseUp}
                        >
                            <div
                                style={{
                                    transform: `translate(${this.state
                                        .pageOffsetX +
                                    this.state.panDeltaX +
                                    this.state.panOffsetX}px, ${this.state
                                        .panDeltaY +
                                    this.state.panOffsetY}px) scale(${this
                                        .state.zoom.fullPage *
                                    this.state.zoom.relative})`,
                                    transformOrigin: '0 0',
                                    display: 'flex',
                                    direction: this.props.reverseReadingMode ? 'rtl' : 'ltr',
                                    justifyContent: this.props.reverseReadingMode ? 'flex-end' : 'flex-start',
                                    transition: this.state.transitionTime,
                                    padding: 0,
                                    backgroundColor: 'rgba (0,0,0)',
                                }}
                            >
                                {this.state.pages.map(function(item, index) {
                                    return (
                                        <ReaderPage
                                            key={item.pageNumber}
                                            isLoaded={item.isLoaded}
                                            url={item.url}
                                            height={item.height}
                                            width={item.width}
                                            comicId={item.comicID}
                                            pageNumber={item.pageNumber}
                                            opacity={item.opacity}
                                        />
                                    );
                                })}
                            </div>

                            {this.showEndCard()}

                            <Modal
                                show={this.state.showModal}
                                onHide={this.handleCloseModal}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {this.state.modalTitle}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{this.state.modalBody}</Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant='primary'
                                        onClick={this.handleCloseModal}
                                    >
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <div className='reader-controls'>
                                {!(this.state.pages.length > 1 && this.state.currentPage === 0 && !this.props.reverseReadingMode) && (
                                    <div
                                    className={`left sidepanel ${this.state.leftSidebarVisible ? 'visible' : ''} ${this.state.showEndCard ? 'dark-background' : ''}`}
                                    style={{ width: this.state.sideBarWidth }}
                                    onClick={
                                        this.props.reverseReadingMode ? this.onNextPageClick : this.onPreviousPageClick
                                    }>
                                    <div className={'inner'}>
                                        <InkyPenButton icon={'Direction'} iconOrientation={'180'} styles={"reader-navigation"} />
                                    </div>
                                </div>)}

                                {!(this.state.pages.length > 1 && this.state.currentPage === 0 && this.props.reverseReadingMode) && (
                                    <div
                                    className={`right sidepanel ${this.state.rightSidebarVisible ? 'visible' : ''}`}
                                    style={{ width: this.state.sideBarWidth }}
                                    onClick={
                                        this.props.reverseReadingMode ? this.onPreviousPageClick : this.onNextPageClick
                                    }>
                                    <div className={'inner'}>
                                        <InkyPenButton icon={'Direction'} styles={'reader-navigation'} />
                                    </div>

                                </div>)}

                            </div>
                        </div>
                    </Hammer>
                </div>
            // </Fullscreen>
        );
    }

    private determineDimensions(): { width: number; height: number } {
        let width = this.state.pages[this.state.currentPage].width;
        let height = this.state.pages[this.state.currentPage].height;
        if (this.props.doublePage && this.state.pages[this.state.currentPage + 1]) {
            width += this.state.pages[this.state.currentPage + 1].width;
            height += this.state.pages[this.state.currentPage + 1].height;
        }

        return { width: width, height: height };
    }
}

export default withRouter(Reader);
