import { OrderedPublishable, Publishable } from './Dashboard';

class DashboardCarousel extends OrderedPublishable {
  series: DashboardSeriesShort;
  order: number;
  imageUrl: string;
  imageLocalPath: string;
  itemUrl: string;

  original: DashboardCarousel;
  seriesID: number;

  displayUpToAgeRating: string;

  getOriginal(): DashboardCarousel {
    return this.original? this.original as DashboardCarousel : null;
  }

  //Anything except order.
  hasChanged(): boolean {
    if(!super.hasChanged()) {
      const orig = this.getOriginal();
      return (orig.series?.id !== this.series?.id
        || orig.imageLocalPath !== this.imageLocalPath
      || orig.itemUrl !== this.itemUrl
      || orig.displayUpToAgeRating !== this.displayUpToAgeRating
      );
    }

    return true;
  }

  canPublish(): boolean {
    return this.imageLocalPath!=null && (this.series != null || this.itemUrl != null && this.itemUrl != "");
  }

  copyFromOriginal(): void {
    super.copyFromOriginal();
    const item = this.getOriginal();
    this.series = item.series;

    this.itemUrl = item.itemUrl;
    this.imageUrl = item.imageUrl;
    this.imageLocalPath = item.imageLocalPath;
    this.displayUpToAgeRating = item.displayUpToAgeRating;
  }
}

class DashboardImageUpload {
  imageUrl: string;
  imageLocalPath: string;
}

class DashboardImageUploadHolder{
  filePaths: DashboardImageUpload[];
}

function createDashboardCarouselFrom(item: DashboardCarousel): DashboardCarousel {
    const dbc = new DashboardCarousel();
    dbc.original = item;
    dbc.copyFromOriginal();

    return dbc;
}

export {DashboardCarousel, DashboardImageUpload, DashboardImageUploadHolder ,createDashboardCarouselFrom};
