import {
  OrderedPublishManager, DashboardCarousel, createDashboardCarouselFrom
} from './';
import dashboard from "../../API/DashboardAPI";
import { CancelTokenSource } from 'axios';

class CarouselManager extends OrderedPublishManager<DashboardCarousel> {

  constructor() {
    super();
    this.fetchItems = async (cancelTokenSource: CancelTokenSource): Promise<DashboardCarousel[]> => {
      const res = await dashboard.getAllCarouselItems();
      console.log(`found ${res.length} carousel items`);
      return res.map(x => createDashboardCarouselFrom(x));
    };

    this.removeItem = dashboard.removeCarouselItem;
    this.publishItem = dashboard.publishCarouselItem;
    this.unPublishItem = dashboard.unPublishCarouselItem;
    this.alterItems = dashboard.alterCarouselItems;
    this.reOrderItems = dashboard.reOrderCarouselItems;
  }

  async addNewCarousel(): Promise<DashboardCarousel> {
    const res = await dashboard.addNewCarouselItem(this.tokenSource, new DashboardCarousel());
    return createDashboardCarouselFrom(res.response as DashboardCarousel);
  }

  async addNewHeroBanner(): Promise<DashboardCarousel> {
    const item = new DashboardCarousel();
    item.isHeroBanner = true;
    const res = await dashboard.addNewCarouselItem(this.tokenSource, item);
    return createDashboardCarouselFrom(res.response as DashboardCarousel);
  }
}

export default CarouselManager;
